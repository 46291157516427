export const useGraphql = async (query, variablesInput) => {
  const data = ref(null)

  const variables = isRef(variablesInput) ? variablesInput : ref(variablesInput)

  const executeQuery = async (variables) => {
    try {
      const result = await useAsyncQuery(query, variables)
      data.value = result.data.value
    } catch (e) {
      console.error(e)
    }
  }

  await executeQuery(variables.value)

  watch(
    () => variables.value,
    (newVariables) => executeQuery(newVariables),
  )

  return { data }
}
