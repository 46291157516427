<script setup>
import RightArrow from '@/assets/img/ico/right-arrow.svg'

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  content: {
    type: String,
    required: true,
  },
})
</script>

<template>
  <div class="default-link">
    <nuxt-link :to="props.content">
      <span>{{ props.text }}</span>
      <RightArrow class="arrow-icon" :filled="true" />
    </nuxt-link>
  </div>
</template>

<script>
export default {
  name: 'DefaultLink',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
