<script setup>
const props = defineProps({
  triggerSourceMobile: {
    type: String,
    default: '',
  },
  lista: {
    type: Array,
    default: [],
  },
})

const { triggerSourceMobile } = toRefs(props)

watch(triggerSourceMobile, (newValue) => {
  // console.log('triggerSourceMobile:', newValue)
})

const localePath = useLocalePath()

const container = ref(null)

onMounted(() => {
  updateVh()
  window.addEventListener('resize', updateVh)
  if (container.value) {
    container.value.classList.add('scrollable-content')
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateVh)
})

const updateVh = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const currentLocalePath = computed(() => {
  return triggerSourceMobile.value === 'infanzia-mobile'
    ? 'infanzia-slug'
    : 'primaria-slug'
})

const emit = defineEmits(['close', 'goBack'])

const emitEvents = () => {
  emit('goBack')
  emit('close')
}
</script>

<template>
  <div class="nav-menu-mobile second-level">
    <div class="nav-overlay-mobile__content">
      <div class="nav-overlay-mobile__links m-top-xxl-1">
        <div class="top-links">
          <div class="back-link">
            <img src="@/assets/img/ico/left-arrow.svg?url" alt="Arrow" />
            <p class="back" @click="emit('goBack')">indietro</p>
          </div>
        </div>

        <div ref="container" class="container" data-lenis-prevent>
          <div v-for="(item, index) in props.lista" :key="index">
            <div class="servizio" @click="emitEvents">
              <nuxt-link
                v-if="item.slug"
                :to="
                  localePath({
                    name: currentLocalePath,
                    params: { slug: item.slug },
                  })
                "
              >
                <div v-if="item.titolo">
                  {{ item.titolo }}
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecondLevelMenuMobile',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
