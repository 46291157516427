import revive_payload_client_Ynle7zAsEe from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sD1UrDqR5Q from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Ca0UkEVLpd from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_y5kMrLObFl from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.21.3_vite@5.4.6_vue@3.5.6/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_NSjHxBYnfh from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_mpwtKNjhtZ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_UufZQzVtOl from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_U6CB3AL7x1 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_WDs8cMsseg from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.3_typescript@5.6.2_vue@3.5.6/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_IuAY77LgZQ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@types+node@22.5.5_eslint@8.57.1_rollup@4.21.3_sass@1.79.1_stylelint@16.9.0_typescript@5.6.2_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_pAQaEMSnCL from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.13.2_rollup@4.21.3_vue@3.5.6/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import nuxt_plugin_ajU01DqRLF from "/opt/build/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_rollup@4.21.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_t9uo0VLAoR from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.2.6_rollup@4.21.3_vite@5.4.6_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_whGkaXn9wU from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.8_rollup@4.21.3_typescript@5.6.2_vue@3.5.6/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_8rAQ1wjiwV from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@4.21.3_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ZDWvn13DfA from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_rollup@4.21.3_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
export default [
  revive_payload_client_Ynle7zAsEe,
  unhead_sD1UrDqR5Q,
  router_Ca0UkEVLpd,
  _0_siteConfig_y5kMrLObFl,
  payload_client_NSjHxBYnfh,
  navigation_repaint_client_mpwtKNjhtZ,
  check_outdated_build_client_UufZQzVtOl,
  chunk_reload_client_U6CB3AL7x1,
  plugin_vue3_WDs8cMsseg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_IuAY77LgZQ,
  plugin_pAQaEMSnCL,
  nuxt_plugin_ajU01DqRLF,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_t9uo0VLAoR,
  plugin_whGkaXn9wU,
  switch_locale_path_ssr_8rAQ1wjiwV,
  i18n_ZDWvn13DfA,
  apollo_IRWTEOPleP,
  sentry_client_GoGQuZo4Et
]