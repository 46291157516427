import { gql } from 'graphql-tag'
import { responsiveImageFragment } from '../fragments/images.js'
import { seoFragment } from '../fragments/seo.js'

export const serviziNav = gql`
  ${responsiveImageFragment}
  query ServiziNav($locale: SiteLocale) {
    allInfanziaServizios(locale: $locale) {
      _allSlugLocales {
        value
        locale
      }
      slug
      titolo
      immagine {
        responsiveImage(imgixParams: { fm: webp, q: "50" }) {
          ...ResponsiveImageFragment
        }
      }
    }
    allPrimariaServizios(locale: $locale) {
      _allSlugLocales {
        value
        locale
      }
      slug
      titolo
      immagine {
        responsiveImage(imgixParams: { fm: webp, q: "50" }) {
          ...ResponsiveImageFragment
        }
      }
    }
  }
`

export const infanziaServizioPage = gql`
  ${seoFragment}
  ${responsiveImageFragment}
  query InfanziaServizioPage($locale: SiteLocale, $slug: String) {
    infanziaServizio(locale: $locale, filter: { slug: { eq: $slug } }) {
      seo {
        ...SeoFragment
      }
      titolo
      slug
      hero {
        titolo
        testo
        cards {
          titolo
          testo
          id
          coloreCard {
            hex
          }
          cta {
            testo
            link
            evento
            file {
              url
            }
          }
        }
      }
      body {
        ... on CaroselloRecord {
          id
          slide {
            immagine {
              responsiveImage(imgixParams: { fm: webp, q: "50" }) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on TitoloTestoDoppiaColonnaRecord {
          id
          primaColonna
          secondaColonna
          sottotitolo
          titolo
          immagineDecorativa {
            titolo
            immagine {
              responsiveImage(imgixParams: { fm: webp, q: "50" }) {
                ...ResponsiveImageFragment
              }
            }
          }
          parolaTarget
          cta {
            testo
            link
            file {
              url
            }
          }
        }
        ... on ImmagineTestoRecord {
          titolo
          immagineADestra
          testo
          landscape: immagine {
            responsiveImage(imgixParams: { fm: webp, q: "50" }) {
              ...ResponsiveImageFragment
            }
          }
          portrait: immagine {
            responsiveImage(
              imgixParams: { auto: compress, q: "50", ar: "1:1", fit: crop }
            ) {
              ...ResponsiveImageFragment
            }
          }
          cta {
            testo
            link
            file {
              url
            }
          }
        }
        ... on TestoImmagineWideRecord {
          titolo
          testo
          landscape: immagine {
            responsiveImage(imgixParams: { fm: webp, q: "50" }) {
              ...ResponsiveImageFragment
            }
          }
          portrait: immagine {
            responsiveImage(
              imgixParams: { auto: compress, q: "50", ar: "1:1", fit: crop }
            ) {
              ...ResponsiveImageFragment
            }
          }
        }
        ... on SezioneImmagineTestoCardRecord {
          id
          titolo
          card {
            titolo
            testo
            immagine {
              responsiveImage(imgixParams: { fm: webp, q: "50" }) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on SezioneGiornataTipoRecord {
          id
          attivita {
            titolo
            testo
          }
        }
      }
      bloccoVisite {
        titolo
        testo
        immagineDecorativa {
          titolo
          immagine {
            responsiveImage(imgixParams: { fm: webp, q: "50" }) {
              ...ResponsiveImageFragment
            }
          }
        }
        cta {
          testo
          link
          file {
            url
          }
        }
      }
    }
    allInfanziaServizios(locale: $locale) {
      _allSlugLocales {
        value
        locale
      }
      slug
      titolo
      immagine {
        responsiveImage(imgixParams: { fm: webp, q: "50" }) {
          ...ResponsiveImageFragment
        }
      }
    }
  }
`

export const primariaServizioPage = gql`
  ${seoFragment}
  ${responsiveImageFragment}
  query PrimariaServizioPage($locale: SiteLocale, $slug: String) {
    primariaServizio(locale: $locale, filter: { slug: { eq: $slug } }) {
      seo {
        ...SeoFragment
      }
      titolo
      slug
      hero {
        titolo
        testo
        cards {
          titolo
          testo
          id
          coloreCard {
            hex
          }
          cta {
            testo
            link
            evento
            file {
              url
            }
          }
        }
      }
      body {
        ... on CaroselloRecord {
          id
          slide {
            immagine {
              responsiveImage(imgixParams: { fm: webp, q: "50" }) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on TitoloTestoDoppiaColonnaRecord {
          id
          primaColonna
          secondaColonna
          sottotitolo
          titolo
          immagineDecorativa {
            titolo
            immagine {
              responsiveImage(imgixParams: { fm: webp, q: "50" }) {
                ...ResponsiveImageFragment
              }
            }
          }
          parolaTarget
          cta {
            testo
            link
            file {
              url
            }
          }
        }
        ... on ImmagineTestoRecord {
          titolo
          immagineADestra
          testo
          landscape: immagine {
            responsiveImage(imgixParams: { fm: webp, q: "50" }) {
              ...ResponsiveImageFragment
            }
          }
          portrait: immagine {
            responsiveImage(
              imgixParams: { auto: compress, q: "50", ar: "1:1", fit: crop }
            ) {
              ...ResponsiveImageFragment
            }
          }
          cta {
            testo
            link
            file {
              url
            }
          }
        }
        ... on TestoImmagineWideRecord {
          titolo
          testo
          landscape: immagine {
            responsiveImage(imgixParams: { fm: webp, q: "50" }) {
              ...ResponsiveImageFragment
            }
          }
          portrait: immagine {
            responsiveImage(
              imgixParams: { auto: compress, q: "50", ar: "1:1", fit: crop }
            ) {
              ...ResponsiveImageFragment
            }
          }
        }
        ... on SezioneImmagineTestoCardRecord {
          id
          titolo
          card {
            titolo
            testo
            immagine {
              responsiveImage(imgixParams: { fm: webp, q: "50" }) {
                ...ResponsiveImageFragment
              }
            }
          }
        }
        ... on SezioneTabellaRecord {
          id
          tabella
        }
        ... on SezioneRecensioniRecord {
          titolo
          sottotitolo
          immagineDecorativa {
            titolo
            immagine {
              responsiveImage(imgixParams: { fm: webp, q: "50" }) {
                ...ResponsiveImageFragment
              }
            }
          }
          parolaTarget
          bloccoRecensione {
            testo
            autore
            id
          }
        }
      }
      bloccoVisite {
        titolo
        testo
        immagineDecorativa {
          titolo
          immagine {
            responsiveImage(imgixParams: { fm: webp, q: "50" }) {
              ...ResponsiveImageFragment
            }
          }
        }
        cta {
          testo
          link
          file {
            url
          }
        }
      }
    }
    allPrimariaServizios(locale: $locale) {
      _allSlugLocales {
        value
        locale
      }
      slug
      titolo
      immagine {
        responsiveImage(imgixParams: { fm: webp, q: "50" }) {
          ...ResponsiveImageFragment
        }
      }
    }
  }
`
