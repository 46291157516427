<script setup>
const { lenis } = useLenis()
const route = useRoute()

provide('lenis', lenis)

watch(
  () => route.name,
  () => {
    lenis.value.scrollTo(0, { immediate: true })
  },
)
</script>

<template>
  <div>
    <!-- https://vite-pwa-org.netlify.app/frameworks/nuxt.html#pwa-assets -->
    <!-- <NuxtPwaAssets /> -->
    <NuxtPwaManifest />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
