import { defineNuxtPlugin } from '#app/nuxt'
import { LazyActivityList, LazyDecorationImage, LazyDefaultBtn, LazyDefaultLink, LazyDefaultPicture, LazyDoubleBlockCards, LazyDoubleBlockText, LazyFixedSideMenu, LazyForm, LazyImgTextCardsOrdered, LazyImgTextCards, LazyImgText, LazyModulisticaBlock, LazyRecensioni, LazyServiziHero, LazySwiperPicture, LazySwiperSlideContent, LazySwiperSlider, LazyTableBlock, LazyTextImageWide, LazyTitleDoubleColText, LazyTitleText, LazyTripleBlock, LazyVideoYoutube, LazyVideo } from '#components'
const lazyGlobalComponents = [
  ["ActivityList", LazyActivityList],
["DecorationImage", LazyDecorationImage],
["DefaultBtn", LazyDefaultBtn],
["DefaultLink", LazyDefaultLink],
["DefaultPicture", LazyDefaultPicture],
["DoubleBlockCards", LazyDoubleBlockCards],
["DoubleBlockText", LazyDoubleBlockText],
["FixedSideMenu", LazyFixedSideMenu],
["Form", LazyForm],
["ImgTextCardsOrdered", LazyImgTextCardsOrdered],
["ImgTextCards", LazyImgTextCards],
["ImgText", LazyImgText],
["ModulisticaBlock", LazyModulisticaBlock],
["Recensioni", LazyRecensioni],
["ServiziHero", LazyServiziHero],
["SwiperPicture", LazySwiperPicture],
["SwiperSlideContent", LazySwiperSlideContent],
["SwiperSlider", LazySwiperSlider],
["TableBlock", LazyTableBlock],
["TextImageWide", LazyTextImageWide],
["TitleDoubleColText", LazyTitleDoubleColText],
["TitleText", LazyTitleText],
["TripleBlock", LazyTripleBlock],
["VideoYoutube", LazyVideoYoutube],
["Video", LazyVideo],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
