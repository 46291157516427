<script setup>
defineProps({
  open: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['toggle'])
</script>

<template>
  <button
    :class="{ '--open': open }"
    :aria-haspopup="true"
    aria-label="Menu Mobile"
    class="hamburger"
    type="button"
    @click="emit('toggle')"
  >
    <span class="hamburger__line" />
    <span class="hamburger__line" />
    <span class="hamburger__line" />
  </button>
</template>

<script>
export default {
  name: 'Hamburger',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
