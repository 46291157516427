<script setup>
const props = defineProps({
  image: {
    type: Object,
    default: null,
  },
  lazyLoad: Boolean,
  alt: {
    type: String,
    default: '',
  },
})

const alt = computed(() => {
  return props?.alt?.replace(/(<([^>]+)>)/gi, '')
})

const src = computed(() => {
  if (props.image?.landscape?.url || props.image?.landscape?.src) {
    return props.image?.landscape?.url || props.image?.landscape?.src
  } else if (props.image?.portrait?.url || props.image?.portrait?.src) {
    return props.image?.portrait?.url || props.image?.portrait?.src
  } else if (props.image?.custom?.url || props.image?.custom?.src) {
    return props.image?.custom?.url || props.image?.custom?.src
  } else {
    return undefined
  }
})

const sizes = computed(() => {
  if (props.image?.landscape) {
    return {
      width: props.image.landscape.width,
      height: props.image.landscape.height,
      sizes: props.image.landscape.sizes,
    }
  } else if (props.image?.portrait) {
    return {
      width: props.image.portrait.width,
      height: props.image.portrait.height,
      sizes: props.image.portrait.sizes,
    }
  } else if (props.image?.custom) {
    return {
      width: props.image.custom.width,
      height: props.image.custom.height,
      sizes: props.image.custom.sizes,
    }
  } else {
    return undefined
  }
})
</script>

<template>
  <picture>
    <source
      v-if="props.image?.landscape?.webpSrcSet"
      media="(orientation: landscape)"
      :srcset="props.image.landscape.webpSrcSet"
    />

    <source
      v-if="props.image?.portrait?.webpSrcSet"
      media="(orientation: portrait)"
      :srcset="props.image.portrait.webpSrcSet"
    />

    <source
      v-if="props.image?.custom?.webpSrcSet"
      :srcset="props.image.custom.webpSrcSet"
    />

    <img
      :src="src"
      :alt="alt"
      :loading="props.lazyLoad ? 'lazy' : 'auto'"
      :width="sizes?.width"
      :height="sizes?.height"
      :sizes="sizes?.sizes"
    />
  </picture>
</template>

<script>
export default {
  name: 'WidgetPicture',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
