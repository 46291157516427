<script setup>
import { serviziNav } from '@/graphql/queries/servizi-nav.js'
import { useMainStore } from '~/store'

const { locale } = useI18n()

const variables = computed(() => ({
  locale: locale.value,
}))
const { data } = await useGraphql(serviziNav, variables)

if (
  !data.value ||
  !data.value.allInfanziaServizios ||
  !data.value.allPrimariaServizios
) {
  throw createError({ statusCode: 404 })
}

const lista = computed(() => {
  if (triggerSource.value === 'infanzia') {
    return data.value.allInfanziaServizios || []
  } else if (triggerSource.value === 'primaria') {
    return data.value.allPrimariaServizios || []
  } else {
    return []
  }
})

const route = useRoute()

const store = useMainStore()

const isHovered = reactive({})

function setHoverState(link, state) {
  isHovered[link] = state
}

const isInfanziaActive = computed(() => route.path.includes('/infanzia/'))
const isPrimariaActive = computed(() => route.path.includes('/primaria/'))
const isNotizieActive = computed(() => route.path.includes('/notizie'))

let closeTimeout
const isTriggeredFromInfanzia = ref(false)
const isTriggeredFromPrimaria = ref(false)

const openInfanziaOverlay = () => {
  isTriggeredFromInfanzia.value = true
  isTriggeredFromPrimaria.value = false
  clearTimeout(closeTimeout)
  store.isOverlayOpen = true
}

const openPrimariaOverlay = () => {
  isTriggeredFromInfanzia.value = false
  isTriggeredFromPrimaria.value = true
  clearTimeout(closeTimeout)
  store.isOverlayOpen = true
}

const handleMouseOver = () => {
  if (isTriggeredFromInfanzia.value || isTriggeredFromPrimaria.value) {
    clearTimeout(closeTimeout)
    store.isOverlayOpen = true
  }
}

const closeOverlay = () => {
  store.setOverlay(false)
  isTriggeredFromInfanzia.value = false
  isTriggeredFromPrimaria.value = false
}

const delayedCloseOverlay = () => {
  closeTimeout = setTimeout(() => {
    closeOverlay()
    isTriggeredFromInfanzia.value = false
    isTriggeredFromPrimaria.value = false
  }, 300)
}

// Hide / Show nav
const lastScrollPosition = ref(0)
const header = ref(null)

const handleScroll = () => {
  const currentScrollPosition =
    window.scrollY || document.documentElement.scrollTop
  if (currentScrollPosition > lastScrollPosition.value) {
    // Scrolling down
    header.value.classList.add('hide-nav')
  } else {
    // Scrolling up
    header.value.classList.remove('hide-nav')
  }
  lastScrollPosition.value =
    currentScrollPosition <= 0 ? 0 : currentScrollPosition
  closeOverlay()
}

const triggerSource = computed(() => {
  let source = ''
  if (isTriggeredFromInfanzia.value) source = 'infanzia'
  if (isTriggeredFromPrimaria.value) source = 'primaria'
  return source
})

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  clearTimeout(closeTimeout)
})
</script>

<template>
  <div class="header__wrapper">
    <header
      ref="header"
      :class="[
        {
          '--overlay-open': store.isOverlayOpen,
          '--overlay-open-mobile': store.open,
        },
      ]"
      class="main-menu"
    >
      <!-- Navigation -->
      <nav class="main-menu__wrap">
        <div class="main-menu__left" @click="closeOverlay">
          <!-- Navigation Logo -->
          <div class="logo">
            <nuxt-link :to="localePath({ name: 'index' })" class="anim-opacity">
              <img
                src="@/assets/img/logo-istituto-canossiano.svg?url"
                alt="Istituto Canossiano"
                class="logo-istituto-canossiano"
              />
            </nuxt-link>
          </div>
        </div>

        <div class="main-menu__center">
          <!-- Navigation Main Links -->
          <div class="main-menu__links show-for-large">
            <div
              class="main-menu__section-link text-s-1"
              :class="{ 'hover-state': isTriggeredFromInfanzia }"
              @mouseover="
                () => {
                  isTriggeredFromInfanzia = true
                  openInfanziaOverlay()
                }
              "
              @mouseout="delayedCloseOverlay"
              @click="closeOverlay"
            >
              <nuxt-link
                :to="localePath({ name: '/' })"
                :class="{ 'active-state': isInfanziaActive }"
              >
                <div class="link-with-icon">
                  Infanzia
                  <img
                    v-if="
                      $route.path.startsWith('/infanzia') ||
                      isTriggeredFromInfanzia
                    "
                    src="@/assets/img/nav-ico/infanzia.svg?url"
                    alt="Icon"
                    class="active-icon"
                  />
                  <img
                    src="@/assets/img/ico/arrow-down.svg?url"
                    alt="Icon"
                    class="icon"
                  />
                </div>
              </nuxt-link>
            </div>

            <div
              class="main-menu__section-link text-s-1"
              :class="{ 'hover-state': isTriggeredFromPrimaria }"
              @mouseover="
                () => {
                  isTriggeredFromPrimaria = true
                  openPrimariaOverlay()
                }
              "
              @mouseout="delayedCloseOverlay"
              @click="closeOverlay"
            >
              <nuxt-link
                :to="localePath({ name: '/' })"
                :class="{ 'active-state': isPrimariaActive }"
              >
                <div class="link-with-icon">
                  Primaria
                  <img
                    v-if="
                      $route.path.startsWith('/primaria') ||
                      isTriggeredFromPrimaria
                    "
                    src="@/assets/img/nav-ico/primaria.svg?url"
                    alt="Icon"
                    class="active-icon"
                  />
                  <img
                    src="@/assets/img/ico/arrow-down.svg?url"
                    alt="Icon"
                    class="icon"
                  />
                </div>
              </nuxt-link>
            </div>

            <div
              class="main-menu__section-link text-s-1"
              @mouseover="setHoverState('istituto', true)"
              @mouseleave="setHoverState('istituto', false)"
            >
              <nuxt-link
                :to="
                  localePath({
                    name: 'istituto',
                  })
                "
              >
                Istituto
                <img
                  v-if="$route.path === '/istituto' || isHovered['istituto']"
                  src="@/assets/img/nav-ico/istituto.svg?url"
                  alt="Icon"
                  class="active-icon"
                />
              </nuxt-link>
            </div>

            <div
              class="main-menu__section-link text-s-1"
              @mouseover="setHoverState('associazione', true)"
              @mouseleave="setHoverState('associazione', false)"
            >
              <nuxt-link
                :to="
                  localePath({
                    name: 'associazione',
                  })
                "
              >
                Associazione Genitori
                <img
                  v-if="
                    $route.path === '/associazione' || isHovered['associazione']
                  "
                  src="@/assets/img/nav-ico/associazione.svg?url"
                  alt="Icon"
                  class="active-icon"
                />
              </nuxt-link>
            </div>

            <div
              class="main-menu__section-link text-s-1"
              @mouseover="setHoverState('modulistica', true)"
              @mouseleave="setHoverState('modulistica', false)"
            >
              <nuxt-link
                :to="
                  localePath({
                    name: 'modulistica',
                  })
                "
              >
                Modulistica
                <img
                  v-if="
                    $route.path === '/modulistica' || isHovered['modulistica']
                  "
                  src="@/assets/img/nav-ico/modulistica.svg?url"
                  alt="Icon"
                  class="active-icon"
                />
              </nuxt-link>
            </div>

            <div
              class="main-menu__section-link text-s-1"
              @mouseover="setHoverState('notizie', true)"
              @mouseleave="setHoverState('notizie', false)"
            >
              <nuxt-link
                :class="{ 'active-state': isNotizieActive }"
                :to="
                  localePath({
                    name: 'notizie',
                  })
                "
              >
                Notizie
                <img
                  v-if="isNotizieActive || isHovered['notizie']"
                  src="@/assets/img/nav-ico/notizie.svg?url"
                  alt="Icon"
                  class="active-icon"
                />
              </nuxt-link>
            </div>
          </div>
        </div>

        <div class="main-menu__right">
          <div class="iscrizioni-container">
            <div
              class="main-menu__section-link text-s-1 show-for-large iscrizioni"
            >
              <nuxt-link
                :to="
                  localePath({
                    name: 'iscrizioni',
                  })
                "
                class="button-round-1 iscrizioni-link"
              >
                <span>Iscrizioni</span>
              </nuxt-link>
            </div>
          </div>

          <NavigationHamburger
            class="hide-for-large"
            :open="store.open"
            @toggle="store.toggleOverlay"
          />
        </div>
      </nav>

      <!-- Navigation Desktop Submenu Overlay  -->
      <NavigationOverlayDesktop
        :class="{
          show:
            store.isOverlayOpen &&
            (isTriggeredFromInfanzia || isTriggeredFromPrimaria),
        }"
        class="show-for-large"
        :trigger-source="triggerSource"
        :lista="lista"
        @mouseover="handleMouseOver"
        @mouseout="delayedCloseOverlay"
        @close="closeOverlay"
      />

      <!-- Navigation Mobile Overlay  -->
      <NavigationOverlayMobile
        :show="store.open"
        class="hide-for-large"
        @close="store.toggleOverlay"
      />
    </header>
  </div>
</template>

<script>
export default {
  name: 'NavigationHeader',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
