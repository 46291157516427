// import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(() => {})

/*
export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const runtimeConfig = useRuntimeConfig()

  const dns = runtimeConfig.public?.sentryURL

  if (!dns) {
    console.warn('Missing Sentry dns')
    return
  }
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: dns,
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 1.0,
  })
})
*/
