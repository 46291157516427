<script setup>
import { serviziNav } from '@/graphql/queries/servizi-nav.js'
import Right from '@/assets/img/ico/arrow-up.svg'

const props = defineProps({
  lista: {
    type: Array,
    default: [],
  },
})

const { locale } = useI18n()

const variables = computed(() => ({
  locale: locale.value,
}))

const { data } = await useGraphql(serviziNav, variables)

if (
  !data.value ||
  !data.value.allInfanziaServizios ||
  !data.value.allPrimariaServizios
) {
  throw createError({ statusCode: 404 })
}

const lista = computed(() => {
  if (trigger.value === 'infanzia-mobile') {
    return data.value.allInfanziaServizios || []
  } else if (trigger.value === 'primaria-mobile') {
    return data.value.allPrimariaServizios || []
  } else {
    return []
  }
})

const emit = defineEmits(['close'])

const isSecondLevelMenuVisible = ref(false)
const trigger = ref('')

const toggleSecondLevelMenu = (newTrigger = '') => {
  trigger.value = newTrigger
  isSecondLevelMenuVisible.value = !isSecondLevelMenuVisible.value
}

onMounted(() => {
  updateVh()
  window.addEventListener('resize', updateVh)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateVh)
})

const updateVh = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}
</script>

<template>
  <div class="nav-menu-mobile">
    <div v-if="!isSecondLevelMenuVisible" class="nav-overlay-mobile__content">
      <!-- General links -->
      <ul class="nav-overlay-mobile__links m-top-xxl-1">
        <li
          class="nav-overlay-mobile__link"
          @click="toggleSecondLevelMenu('infanzia-mobile')"
        >
          Scuola infanzia
          <Right :filled="true" />
        </li>
        <li
          class="nav-overlay-mobile__link"
          @click="toggleSecondLevelMenu('primaria-mobile')"
        >
          Scuola primaria
          <Right :filled="true" />
        </li>
        <li class="nav-overlay-mobile__link">
          <nuxt-link
            :to="
              localePath({
                name: 'istituto',
              })
            "
            @click="emit('close')"
          >
            Istituto
          </nuxt-link>
        </li>
        <li class="nav-overlay-mobile__link">
          <nuxt-link
            :to="
              localePath({
                name: 'associazione',
              })
            "
            @click="emit('close')"
          >
            Associazione Genitori
          </nuxt-link>
        </li>
        <li class="nav-overlay-mobile__link">
          <nuxt-link
            :to="
              localePath({
                name: 'modulistica',
              })
            "
            @click="emit('close')"
          >
            Modulistica
          </nuxt-link>
        </li>
        <li class="nav-overlay-mobile__link">
          <nuxt-link
            :to="
              localePath({
                name: 'notizie',
              })
            "
            @click="emit('close')"
          >
            Notizie
          </nuxt-link>
        </li>
      </ul>

      <div class="nav-overlay-mobile__text">
        <div class="iscrizioni">
          <nuxt-link
            :to="
              localePath({
                name: 'iscrizioni',
              })
            "
            class="button-round-1 is-mobile"
            @click="emit('close')"
          >
            <span>Iscrizioni</span>
          </nuxt-link>
        </div>
        <div class="bottom">
          <div>
            <a href="tel:0495384032" @click="$emit('close')">
              Tel. 049 5384032
            </a>
          </div>
          <div>
            <a href="mailto:conslvpd@fdcc.org" @click="$emit('close')"
              >conslvpd@fdcc.org</a
            >
          </div>
        </div>
      </div>
    </div>
    <NavigationOverlayMobileSecondLevelMenu
      v-else
      :trigger-source-mobile="trigger"
      :lista="lista"
      @go-back="toggleSecondLevelMenu"
      @close="emit('close')"
    />
  </div>
</template>

<script>
export default {
  name: 'MenuMobile',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
