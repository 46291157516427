<script setup>
// Qui serve la variabile (reattiva) in defineProps perchè la si usa in watchEffect
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
})

onMounted(() => {
  updateVh()
  window.addEventListener('resize', updateVh)
  watchEffect(() => {
    if (props.show) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateVh)
})

const updateVh = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const emit = defineEmits(['close'])
</script>

<template>
  <div class="nav-overlay-mobile" :class="{ '--show': props.show }">
    <div class="nav-overlay-mobile__container">
      <div class="nav-overlay-mobile__content">
        <NavigationOverlayMobileMenu @close="emit('close')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverlayMobile',
}
</script>

<style lang="scss">
@import './style.scss';
</style>
