import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    open: false,
    isOverlayOpen: false,
  }),
  getters: {},
  actions: {
    setOpen(value) {
      this.open = value
    },
    setOverlay(value) {
      this.isOverlayOpen = value
    },
    toggleOverlay() {
      this.open = !this.open
    },
  },
})
