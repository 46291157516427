<script setup>
const props = defineProps({
  error: {
    type: Object,
    default: null,
  },
})

console.error(props.error)

const { lenis } = useLenis()
provide('lenis', lenis)
</script>

<template>
  <div>
    <NuxtLayout>
      <NavigationHeader />

      <div
        class="page page-error m-top-m-1 row-400-25 p-top-xxxxl-1 p-bottom-xxxxl-1"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="title title-base"
          v-html="
            props.error.statusCode === 500
              ? $t('errors.500.text')
              : $t('errors.404.text')
          "
        />
        <br />
        <div class="underline-animation p-top-1">
          <DefaultLink
            class="icon-link text-custom is-semi-bold"
            :text="'Homepage'"
            :content="'/'"
          />
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
